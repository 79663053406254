<template>
  <div class="Main Site" style="overflow: visible">
    <van-nav-bar
      fixed
      :border="false"
      :title="navBarTitle"
      :left-arrow="$route.name == 'user' ? false : false"
      :right-text="rightText"
      @click-left="onClickLeft"
      @click-right="onClickRight"
      v-if="$route.name != 'user' && $route.name != 'myTask'"
      :style="$route.name == 'promote' ? 'background:transparent' : ''"
    ><img src="~@/assets/img/skinbitcoin/back.png" slot="left"></van-nav-bar>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "UserCenter",
  components: {},
  props: [],
  data() {
    return {
      rightText: "",
      navBarTitle: "",
    };
  },
  computed: {},
  watch: {
    $route(to, from) {
      switch (to.name) {
        case "recharge":
          break;
        default:
          this.rightText = "";
      }
    },
  },
  created() {
    switch (this.$route.name) {
      case "recharge":
        break;
      default:
        this.rightText = "";
    }
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    onClickLeft() {
      switch (this.$route.name) {
        case "taskRecord":
        case "postRecord":
        case "auditRecord":
        case "wallet":
          this.$router.push("/user");
          break;
        case "recharge":
          if (this.$children[1].showPrice) {
            this.$children[1].showPrice = false;
          } else {
            this.$router.go(-1);
          }
          break;
        default:
          this.$router.go(-1);
      }
    },
    onClickRight() {
      if (this.$route.name == "recharge") {
        this.$router.push("/user/wallet");
      }
    },
  },
};
</script>
<style scoped>
.van-nav-bar {
  background-color:  #000000 !important;
}
.van-nav-bar >>> .van-icon {
  color: white !important;
}


.van-dialog__message {
  color: var(--bc-text-color-1);
}
</style>
